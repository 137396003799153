import React, { useEffect } from 'react'
import Layout from '../../components/Demo/Layout'
import Valentine from '../../components/2021/Valentine/Valentine'

const Valentine2021Page = () => {
  const pageSlug = 'valentine2021'

  return (
    <Layout pageSlug={pageSlug}>
      <Valentine />
    </Layout>
  )
}

export default Valentine2021Page
